import React, { useState, useEffect, useRef } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import get from 'lodash.get'
import { BREAKPOINT_MENU } from '../../utils/constants/responsive'
import useMedia from 'use-media'
// import Link from '../Link'
import Logo from '../Logo'
import './style.scss'
import Weather from '../Weather'

const Header = ({ langKey, data }) => {
  const headerRef = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const hasCorrectWidth = useMedia({ maxWidth: `${BREAKPOINT_MENU}px` })

  const onScroll = () => {
    const content = document.querySelector('.main__content')

    const y = window.scrollY > 0 ? window.scrollY : 0
    setScrollPosition(y)

    if (y <= scrollPosition) {
      headerRef.current.classList.add('header--scroll')
      content.style.marginTop = `${headerRef.current.clientHeight}px`
    } else {
      headerRef.current.classList.remove('header--scroll')
      content.style.marginTop = 0
    }
  }

  useEffect(() => {
    const isMenuOpened = isOpened && hasCorrectWidth
    headerRef.current.classList[isMenuOpened ? 'add' : 'remove']('header--show')
    // Disabling scroll and touch-move body events when menu is opened
    if (isMenuOpened) {
      disableBodyScroll(headerRef.current)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [isOpened, hasCorrectWidth])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })
  const headerData = get(data, 'prismic.allHeaders.edges[0].node')

  return (
    <header className="header" ref={headerRef}>
      <div className="header__top">
        <div className="header__main">
          <h1>
            <Link to={`/${langKey}`}>
              <Logo />
            </Link>
          </h1>
          <a
            className="header__main__btn"
            onClick={() => setIsOpened(!isOpened)}
          >
            menu
          </a>
        </div>
        <div className="header__nav">
          <nav>
            <Link to={`/${langKey}/`}>Home</Link>
            <Link to={`/${langKey}/events`}>Agenda</Link>
            <Link to={`/${langKey}/about`}>Us</Link>
            <Link to={`/${langKey}/about-you`}>You</Link>
            <Link className="header__nav__contact" to={`/${langKey}/contact`}>
              Contact
            </Link>
            <div className="langs">
              <Link to={`/en`}>EN</Link>-<Link to={`/fr`}>FR</Link>
            </div>
            <Weather isMenuOpened={isOpened} />
          </nav>
        </div>
      </div>
      <div className="header__extra">
        <div className="infos">
          <a href={`mailto:${headerData.email[0].text}`}>
            {headerData.email[0].text}
          </a>
          <span>{headerData.address[0].text}</span>
        </div>
        <Weather />
        <div className="langs">
          <Link to={`/en`}>EN</Link>
          <Link to={`/fr`}>FR</Link>
        </div>
      </div>
    </header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHeaders(lang: "fr-fr") {
            edges {
              node {
                email
                address
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
