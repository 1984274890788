import React from 'react'
import gsap, { TweenMax, Power4 } from 'gsap'
import './styles.scss'

class AutoScrollerWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.globalWrapperNode = React.createRef()
    this.wrapperNode = React.createRef()
    this.accelerator = 1 + Math.random() * 0.7
    this.pos = 1
    this.node = null
    this.childNodes = []
    this.lastChildNode = null
    this.childCount = 1
    this.childWidth = 0
  }

  cloneChild = () => {
    const clone = this.lastChildNode.cloneNode(true)
    this.lastChildNode.after(clone)
    this.childCount++
  }

  onTick = (time, deltaTime, frame) => {
    this.pos = frame * this.accelerator
    this.node.style.transform = `translate3d(-${this.pos}px,0,0)`
    this.lastChildNode = this.childNodes[this.childNodes.length - 1]

    const childCountPerViewport = Math.ceil(window.innerWidth / this.childWidth)
    const pastViewportCount = Math.ceil(this.pos / window.innerWidth) + 1
    const wantedChildCount = childCountPerViewport * pastViewportCount

    if (this.childCount < wantedChildCount) {
      this.cloneChild()
    }
  }

  initAnimation = () => {
    this.node = this.wrapperNode.current
    this.childNodes = this.node.children[0].children
    this.childWidth = this.childNodes[0].clientWidth

    gsap.ticker.add(this.onTick)

    TweenMax.fromTo(
      this.globalWrapperNode.current,
      2.5,
      {
        x: '70%',
        alpha: 0,
      },
      {
        x: 0,
        alpha: 1,
        ease: Power4.easeOut,
        delay: this.props.delay,
      }
    )
  }

  handleResize = () => {
    // Update size
    this.childWidth = this.childNodes[this.childNodes.length - 1].clientWidth
  }

  componentDidMount() {
    this.initAnimation()

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { children } = this.props

    return (
      <div ref={this.globalWrapperNode} style={{ opacity: 0 }}>
        <div
          ref={this.wrapperNode}
          style={{ transform: 'translate3d(0, 0, 0)' }}
          className="auto-scroller-wrapper"
        >
          {children}
        </div>
      </div>
    )
  }
}

export default AutoScrollerWrapper
