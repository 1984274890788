export const getCurrentLanguage = () => {
  if (typeof window !== 'undefined') {
    let path = window.location.pathname
    return path.split('/')[1]
  }
}

export const convertKelvinToCelcius = value => parseInt(value - 273.15, 10)

export const dateFormat = date => {
  date = date.split('-')
  return `${date[2]}.${date[1]}.${date[0]}`
}
