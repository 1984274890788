import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n'
import { IntlProvider } from 'react-intl'
import Header from '../components/Header'
import { weatherData } from '../components/Weather/api'
import '../styles/index.scss'

class Layout extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      weatherData()
    }
  }

  render() {
    const { children, location, i18nMessages, isDark } = this.props

    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                defaultLangKey
                langs
              }
            }
          }
        `}
        render={data => {
          const url = location.pathname
          const { langs, defaultLangKey } = data.site.siteMetadata
          const langKey = getCurrentLangKey(langs, defaultLangKey, url)

          return (
            <IntlProvider locale={langKey} messages={i18nMessages}>
              <div className={`main main-${isDark ? 'dark' : 'light'}`}>
                <Header langKey={langKey} />
                <div className="main__content">{children}</div>
              </div>
            </IntlProvider>
          )
        }}
      />
    )
  }
}

Layout.defaultProps = {
  isDark: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
