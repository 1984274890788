import { convertKelvinToCelcius } from '../../utils'

const API_KEY = 'ae86cac96c4f7bc2f41545ba5a91af83'
const PROXY = 'https://cors-anywhere.herokuapp.com/'

export const API_WEATHER = city =>
  `${PROXY}https://api.openweathermap.org/data/2.5/weather?q=${city}&APPID=${API_KEY}`

export async function getWeatherByCity(city) {
  let response = await fetch(API_WEATHER(city), {
    method: 'GET',
  })
  let data = await response.json()
  return data
}

export async function weatherData() {
  if (!sessionStorage.getItem('weather')) {
    await Promise.all([
      getWeatherByCity('Paris'),
      getWeatherByCity('Brussels'),
      getWeatherByCity('Athens'),
    ]).then(([paris, brussels, athens]) => {
      sessionStorage.setItem(
        'weather',
        JSON.stringify([
          getWeatherObject('Paris', paris),
          getWeatherObject('Brussels', brussels),
          getWeatherObject('Athens', athens),
        ])
      )
    })
  }
}

const getWeatherObject = (city, response) => {
  const temp = convertKelvinToCelcius(response.main.temp)
  const icon = response.weather[0].icon
  let sign = '+'
  if (Math.sign(temp) === 0) {
    sign = ''
  } else if (Math.sign(temp) === -1) {
    sign = '-'
  }

  const weatherByCity = {
    name: city,
    temp: `${sign}${temp}°C`,
    icon: icon,
  }

  return weatherByCity
}
