import React, { useState, useEffect } from 'react'
import { getCurrentLanguage } from '../../utils'
import AutoScrollerWrapper from '../HomepageList/ItemRow/AutoScrollerWrapper'
import './style.scss'
import {
  iconWeahterCloud,
  iconWeahterSun,
  iconWeatherSunCloud,
  iconWeatherBrume,
  iconWeatherLightning,
  iconWeatherSnow,
  iconWeatherRain,
} from './icons'
import useMedia from 'use-media'
import { BREAKPOINT_LARGE } from '../../utils/constants/responsive'

const getCurrentIcon = icon => {
  switch (icon) {
    case '01d' || '01n':
      return (icon = iconWeahterSun)
    case '03d' || '03n' || '04d' || '04n':
      return (icon = iconWeahterCloud)
    case '02d' || '02n':
      return (icon = iconWeatherSunCloud)
    case '50d' || '50n':
      return (icon = iconWeatherBrume)
    case '11d' || '11n':
      return (icon = iconWeatherLightning)
    case '09d' || '09n' || '10d' || '10n':
      return (icon = iconWeatherRain)
    case '13d' || '13n':
      return (icon = iconWeatherSnow)
    default:
      return (icon = iconWeahterCloud)
  }
}

const CitiesWeather = ({ weather, currentLang }) => {
  const translateCity = city => {
    if (currentLang === 'fr') {
      switch (city) {
        case 'Brussels':
          return (city = 'Bruxelles')
        case 'Paris':
          return (city = 'Paris')
        case 'Athens':
          return (city = 'Athènes')
        default:
          return (city = 'Paris')
      }
    }
    return city
  }

  return (
    <div className="cities-block">
      {weather.map(city => (
        <div key={city.name} className="weather__item">
          <div className="city-wrapper">
            {translateCity(city.name)} {city.temp} {getCurrentIcon(city.icon)}
          </div>
        </div>
      ))}
    </div>
  )
}

const Weather = ({ isMenuOpened }) => {
  const [currentLang, setCurrentLang] = useState('fr')
  const [weather, setWeather] = useState([])
  const isWide = useMedia({ minWidth: `${BREAKPOINT_LARGE}px` })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedWeather = sessionStorage.getItem('weather')
      if (storedWeather) {
        setWeather(JSON.parse(storedWeather))
        setCurrentLang(getCurrentLanguage())
      }
    }
  }, [])

  const hasWeather = !!weather && weather.length > 0
  const shouldRender = (isWide || (!isWide && isMenuOpened)) && hasWeather

  return shouldRender ? (
    <div className="weather">
      {isWide ? (
        <CitiesWeather weather={weather} currentLang={currentLang} />
      ) : (
        <AutoScrollerWrapper delay={0}>
          <div>
            <CitiesWeather weather={weather} currentLang={currentLang} />
          </div>
        </AutoScrollerWrapper>
      )}
    </div>
  ) : null
}

export default Weather
