import React from 'react'

const Logo = () => {
  return (
    <svg viewBox="0 0 120 48">
      <g fillRule="nonzero">
        <path d="M29.19 0h11.7v17.89h15.517V0h11.701v47.027H56.407V28.37H40.89v18.657h-11.7zM101.24 0L120 47.027h-13.106l-2.313-6.9H88.135l-2.313 6.9H72.973L91.733 0h9.508zm-4.754 14.568l-5.01 16.102h10.022l-5.012-16.102zM4.49 0l18.213 47.027H9.979l-2.245-6.9H0V30.67h4.74L0 14.98V0z" />
      </g>
    </svg>
  )
}

export default Logo
