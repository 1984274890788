import React from 'react'

export const iconWeahterSun = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="76"
    viewBox="0 0 76 76"
  >
    <path
      fill="#000"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M2.8 40.1h11.5c.4 5 2.4 9.5 5.5 13.2l-8.2 8.2c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6l8.2-8.2c3.6 3 8.2 5 13.2 5.5v11.5c0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1V61.6c5-.4 9.5-2.4 13.2-5.5l8.2 8.2c.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6.8-.8.8-2.1 0-2.9l-8.2-8.2c3-3.6 5-8.2 5.5-13.2h11.5c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1H61.6c-.4-5-2.4-9.5-5.5-13.2l7.4-7.4.7-.7c.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0l-.7.7-7.4 7.4c-3.6-3-8.2-5-13.2-5.5V2.9c0-1.1-.9-2.1-2.1-2.1-1.1 0-2.1.9-2.1 2.1v11.5c-5 .4-9.5 2.4-13.2 5.5l-7.4-7.4-.7-.7c-.8-.8-2.1-.8-2.9 0-.8.8-.8 2.1 0 2.9l.7.7 7.4 7.4c-3 3.6-5 8.2-5.5 13.2H2.8c-1.1 0-2.1.9-2.1 2.1.1 1 1 2 2.1 2zM38 18.4c10.8 0 19.6 8.8 19.6 19.6S48.8 57.6 38 57.6 18.4 48.8 18.4 38 27.2 18.4 38 18.4z"
    ></path>
  </svg>
)

export const iconWeahterCloud = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="60"
    viewBox="0 0 92 60"
  >
    <path
      fill="#000"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M78.4 23.4C78.2 10.7 67.8.4 55.1.4c-7.6 0-14.8 3.8-19.1 10-2.1-1-4.5-1.5-6.8-1.5-9.2 0-16.6 7.5-16.6 16.6v1.1C5.5 28.6.3 35.2.3 42.7c0 8.5 6.4 15.6 14.6 16.6v.1h58.4c10.2 0 18.4-8.3 18.4-18.4 0-8.2-5.5-15.4-13.3-17.6zm-5.1 32H17c-6.9 0-12.6-5.7-12.6-12.6 0-6.2 4.6-11.5 10.7-12.5l2.1-.3-.4-2.1c-.2-.8-.2-1.6-.2-2.4 0-6.9 5.6-12.5 12.5-12.5 2.3 0 4.5.6 6.5 1.8l1.8 1.1 1.1-1.8c3.4-5.9 9.8-9.6 16.6-9.6 10.6 0 19.2 8.6 19.2 19.2v1.2l-.1 1.8 1.8.3c6.8 1.2 11.7 7.2 11.7 14-.1 8-6.5 14.4-14.4 14.4z"
    ></path>
  </svg>
)

export const iconWeatherSunCloud = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98"
    height="76"
    viewBox="0 0 98 76"
  >
    <path
      fill="#000"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M2.8 39.5h11.5c.4 5 2.4 9.5 5.5 13.2l-8.2 8.2c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6l7.4-7.4c-.6 1.6-1 3.4-1 5.2 0 7.8 6.4 14.2 14.2 14.2h46.6c8.6 0 15.6-7 15.6-15.6 0-6.9-4.5-12.9-11-14.9-.3-10.6-9-19.1-19.7-19.1-2.5 0-4.9.5-7.2 1.4-.9-1.9-2-3.7-3.4-5.3l8.2-8.2c.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0l-.7.7-7.4 7.4c-3.6-3-8.2-5-13.2-5.5V2.3c0-1.1-.9-2.1-2.1-2.1-1.2 0-2.1.9-2.1 2.1v11.5c-5 .4-9.5 2.4-13.2 5.5l-7.4-7.4-.7-.7c-.8-.8-2.1-.8-2.9 0-.8.8-.8 2.1 0 2.9l.7.7 7.4 7.4c-3 3.6-5 8.2-5.5 13.2H2.8c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2 2.1 2zm79.3 6.3v.9l-.1 1.9 1.8.3c5.4 1 9.4 5.7 9.4 11.3 0 6.3-5.1 11.5-11.5 11.5H35.2c-5.5 0-10-4.5-10-10.1 0-4.9 3.7-9.2 8.5-9.9l2.1-.3-.4-2.1c-.1-.6-.2-1.3-.2-1.9 0-5.5 4.5-10 10-10 1.8 0 3.6.5 5.2 1.4l1.8 1.1 1.1-1.8c2.8-4.8 7.9-7.7 13.4-7.7 8.5-.1 15.4 6.9 15.4 15.4zM50.7 34.3c-1.7-.7-3.6-1.1-5.5-1.1-7.8 0-14.1 6.3-14.1 14.1v.7c-2.5.7-4.6 2.2-6.3 4-3.9-3.6-6.4-8.7-6.4-14.5 0-10.8 8.8-19.6 19.6-19.6 7.9 0 14.8 4.7 17.8 11.5-2 1.3-3.7 3-5.1 4.9z"
    ></path>
  </svg>
)

export const iconWeatherBrume = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="44"
    viewBox="0 0 76 44"
  >
    <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path d="M7.2 4.3h10.4c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1H7.2C6.1.1 5.1 1 5.1 2.2c0 1.2 1 2.1 2.1 2.1zM28 4.3h10.4c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1H28c-1.1 0-2.1.9-2.1 2.1 0 1.2.9 2.1 2.1 2.1zM48.8 4.3h10.4c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1H48.8c-1.1 0-2.1.9-2.1 2.1 0 1.2.9 2.1 2.1 2.1zM71.1 39.7H60.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h10.4c1.1 0 2.1-.9 2.1-2.1 0-1.2-.9-2.1-2.1-2.1zM50.3 39.7H39.9c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h10.4c1.1 0 2.1-.9 2.1-2.1 0-1.2-.9-2.1-2.1-2.1zM29.5 39.7H19.2c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h10.4c1.1 0 2.1-.9 2.1-2.1-.1-1.2-1-2.1-2.2-2.1zM29.1 15.4c0-1.1-.9-2.1-2.1-2.1H11.5c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h15.6c1.1 0 2-.9 2-2.1zM55.1 15.4c0-1.1-.9-2.1-2.1-2.1H37.5c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h15.6c1.1 0 2-.9 2-2.1zM73.1 13.3h-9.6c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h9.6c1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zM66.6 28.6c0-1.1-.9-2.1-2.1-2.1h-9.6c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h9.6c1.2 0 2.1-1 2.1-2.1zM28.9 30.7h13c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1h-13c-1.1 0-2.1.9-2.1 2.1 0 1.1 1 2.1 2.1 2.1zM18 28.6c0-1.1-.9-2.1-2.1-2.1h-13c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1h13c1.2 0 2.1-1 2.1-2.1z"></path>
    </g>
  </svg>
)

export const iconWeatherLightning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="76"
    viewBox="0 0 74 76"
  >
    <path d="M63.1 18.4C62.8 8.2 54.4.1 44.2.1 38.1.1 32.5 3 28.9 7.9c-1.7-.7-3.4-1.1-5.3-1.1-7.5 0-13.6 6.1-13.6 13.6v.6C4.4 22.7.3 28.1.3 34.1c0 7.5 6.1 13.7 13.7 13.7h7.4l-3 6.6h9.1l-9.8 21.5 35-28.1h5.9c8.3 0 15-6.7 15-15 .1-6.7-4.3-12.5-10.5-14.4zM45.9 48L28.7 61.8l5.2-11.6h-9.1l8.5-18.8H47L39.5 48zm.1-4.4l7.4-16.4H30.7l-7.4 16.4H14c-5.2 0-9.5-4.3-9.5-9.5 0-4.7 3.5-8.7 8.1-9.4l2.1-.3-.4-2.1c-.1-.6-.2-1.2-.2-1.8 0-5.2 4.2-9.4 9.4-9.4 1.7 0 3.4.5 4.9 1.4l1.8 1.1 1.1-1.8c2.6-4.5 7.5-7.4 12.8-7.4 8.1 0 14.8 6.6 14.8 14.8v.9l-.1 1.8 1.8.3c5.2 1 8.9 5.5 8.9 10.7 0 6-4.9 10.9-10.9 10.9z"></path>
  </svg>
)

export const iconWeatherSnow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="74"
    viewBox="0 0 74 74"
  >
    <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path d="M63.1 18.6C62.8 8.4 54.4.3 44.2.3c-6.1 0-11.7 2.9-15.3 7.8-1.7-.7-3.4-1.1-5.3-1.1C16.1 7 10 13.1 10 20.6v.6C4.4 22.9.3 28.3.3 34.3.3 41.8 6.5 48 14 48h44.6c8.3 0 15-6.7 15-15 .1-6.7-4.3-12.5-10.5-14.4zm-4.5 25.2H14c-5.2 0-9.5-4.3-9.5-9.5 0-4.7 3.5-8.7 8.1-9.4l2.1-.3-.4-2.1c-.1-.6-.2-1.2-.2-1.8 0-5.2 4.2-9.4 9.4-9.4 1.7 0 3.4.5 4.9 1.4l1.8 1.1 1.1-1.8c2.6-4.5 7.5-7.4 12.8-7.4 8.1 0 14.8 6.6 14.8 14.8v.9l-.1 1.8 1.8.3c5.2 1 8.9 5.5 8.9 10.7 0 5.9-4.9 10.7-10.9 10.7z"></path>
      <circle cx="17.2" cy="55.8" r="3.4"></circle>
      <circle cx="31.3" cy="62.5" r="3.4"></circle>
      <circle cx="42.7" cy="55.8" r="3.4"></circle>
      <circle cx="19.8" cy="70.3" r="3.4"></circle>
      <circle cx="45.8" cy="70.3" r="3.4"></circle>
      <circle cx="56.2" cy="60" r="3.4"></circle>
    </g>
  </svg>
)

export const iconWeatherRain = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="72"
    viewBox="0 0 74 72"
  >
    <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path d="M16.7 58.6c-1-.5-2.3 0-2.8 1L9.5 69c-.5 1 0 2.3 1 2.8.3.1.6.2.9.2.8 0 1.5-.4 1.9-1.2l4.4-9.4c.5-1.1 0-2.3-1-2.8zM38.1 49.1c-1-.5-2.3 0-2.8 1l-6.6 14.1c-.5 1 0 2.3 1 2.8.3.1.6.2.9.2.8 0 1.5-.4 1.9-1.2l6.6-14.1c.5-1 .1-2.3-1-2.8zM52.8 54.2c-1-.5-2.3 0-2.8 1l-5.8 12.2c-.5 1 0 2.3 1 2.8.3.1.6.2.9.2.8 0 1.5-.4 1.9-1.2L53.8 57c.5-1.1.1-2.3-1-2.8z"></path>
      <path d="M63.1 18.4C62.8 8.2 54.4.1 44.2.1 38.1.1 32.5 3 28.9 7.9c-1.7-.7-3.4-1.1-5.3-1.1-7.5 0-13.6 6.1-13.6 13.6v.6C4.4 22.7.3 28.1.3 34.1c0 7.5 6.1 13.7 13.7 13.7h5.4l-1.1 2.4c-.5 1 0 2.3 1 2.8.3.1.6.2.9.2.8 0 1.5-.4 1.9-1.2l1.9-4.1h34.6c8.3 0 15-6.7 15-15 .1-6.8-4.3-12.6-10.5-14.5zm-4.5 25.2H14c-5.2 0-9.5-4.3-9.5-9.5 0-4.7 3.5-8.7 8.1-9.4l2.1-.3-.4-2.1c-.1-.6-.2-1.2-.2-1.8 0-5.2 4.2-9.4 9.4-9.4 1.7 0 3.4.5 4.9 1.4l1.8 1.1 1.1-1.8c2.6-4.5 7.5-7.4 12.8-7.4 8.1 0 14.8 6.6 14.8 14.8v.9l-.1 1.8 1.8.3c5.2 1 8.9 5.5 8.9 10.7 0 5.9-4.9 10.7-10.9 10.7z"></path>
    </g>
  </svg>
)
